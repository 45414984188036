#app {
    margin-top: -7px;
}
.navbar-transparent {
    top: auto !important;
}
.alert a {
    color: #ffea00 !important;
    font-weight: 900 !important;
}
.navbar-transparent .navbar-nav .nav-link {
    font-weight: bold;
}
.navbar-transparent .navbar-nav .nav-link.active {
    color: rgba(241, 255, 0, 1);
}
