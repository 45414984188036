@import "../global";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

// Bootstrap Functions
@import "~bootstrap/scss/functions";

// Theme Variables
@import "argon/variables";

// Bootstrap Core
@import "~bootstrap/scss/bootstrap";

// Theme Core
@import "argon/theme";

// Customizacao
@import "custom";

// Pages
@import 'pages/dashboard';
